import { rfiHelperApi } from 'api/index'

import { RfiComment } from '@/types/rfis/rfi'

export const postComment = ({
  rfiQuestionId,
  commentText,
  isQuestionComment,
}: {
  rfiQuestionId: string
  commentText: string
  isQuestionComment: boolean
}) =>
  rfiHelperApi.post<RfiComment>('/comments', {
    rfiQuestionId,
    commentText,
    isQuestionComment,
  })
