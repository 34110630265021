import { rfiHelperApi } from 'api/index'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export const fetchTaskStatus = ({ taskId }: { taskId: string }) =>
  rfiHelperApi.get<ProgressApiRes>(`/tasks-status/${taskId}`)

export const fetchTaskStatuses = ({ taskIds }: { taskIds?: string[] }) => {
  const searchString = taskIds?.reduce((acc, taskId, index) => {
    return `${acc}${index === 0 ? '?' : '&'}id=${taskId}`
  }, '')
  return rfiHelperApi.get<ProgressApiRes[]>(`/tasks-status${searchString}`)
}

// export const mockFetchTaskStatus =
//   ({ taskId }: { taskId: string }) =>
//   () => {
//     console.log('mockFetchTaskStatus', taskId)
//     return fetch(`http://localhost:3001/taskStatus/${taskId}`)
//       .then(res => res.json())
//       .then(data => ({ data }))
//       .catch(console.error)
//   }
