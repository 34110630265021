import { WppButton, WppIconAdd, WppSideModal } from '@wppopen/components-library-react'
import { useState } from 'react'

import { UseCasesEditAdd } from '../useCasesEditAdd/UseCasesEditAdd'

export interface Props {
  disabled: boolean
}

export const UseCasesFilterGroup = ({ disabled }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<'filters' | 'add' | ''>('')

  const handleOpenModal = (modal: 'filters' | 'add') => {
    setIsModalOpen(true)
    setModalContent(modal)
  }
  const handleCloseModal = () => setIsModalOpen(false)

  return (
    <div className="flex mb-4 justify-between">
      <div className="flex gap-4 w-1/2">
        <WppSideModal size="m" open={isModalOpen} onWppSideModalClose={handleCloseModal}>
          {modalContent === 'add' && (
            <>
              <h3 slot="header">Add Use Case</h3>
              <div slot="body" className="flex flex-col gap-6">
                <UseCasesEditAdd handleSave={handleCloseModal} handleCancel={handleCloseModal} />
              </div>
            </>
          )}
        </WppSideModal>
      </div>

      <WppButton className="ml-4" variant="primary" onClick={() => handleOpenModal('add')} size="s" disabled={disabled}>
        <WppIconAdd slot="icon-start" />
        New Use Case
      </WppButton>
    </div>
  )
}
