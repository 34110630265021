import { WppModal, WppSpinner, WppTypography } from '@wppopen/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { useRef, useState, useEffect } from 'react'

import { useGetAgency } from 'api/queries/agencies/useGetAgency'
import HeaderCell from 'components/Grid/HeaderCell'
import { useToast } from 'hooks/useToast'
import { previewOrDownloadFile } from 'utils/projectUtils'

import { AgencyDeleteFileModal } from './AgencyDeleteFileModal'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@wppopen/components-library/dist/collection/ag-theme-wpp.css'
import { columnDefenitions } from './columnsDefenitions'
import { AgencyFile } from '@/types/agencies/agencies'

interface AgencyFileListProps {
  agencyId: string
  actionsAllowed?: boolean
}

export type TableDataItem = AgencyFile
const AgencyFileList = ({ agencyId, actionsAllowed = true }: AgencyFileListProps) => {
  const toast = useToast()
  const { data: agency, isLoading } = useGetAgency({ params: { id: agencyId } })
  const handleDownloadFileClick = (url: string, fileName: string) => {
    previewOrDownloadFile(null, url, fileName).then(
      success => !success && toast.showToast({ message: 'Failed to download file', type: 'error' }),
    )
  }
  const handleDeleteFileClick = (id: string) => {
    setfileId(id)
  }
  const gridRef = useRef<AgGridReact<TableDataItem>>(null)
  const columnDefs = columnDefenitions(actionsAllowed, handleDownloadFileClick, handleDeleteFileClick)
  const [fileId, setfileId] = useState<string | null>(null)
  const [fileList, setFileList] = useState<AgencyFile[]>([])
  useEffect(() => {
    if (agency.files) {
      setFileList(agency.files)
    }
  }, [agency.files])

  return (
    <div className={clsx('flex flex-col gap-5')}>
      <div>
        <WppTypography type="xl-heading">Files</WppTypography>
        <br />
        <WppTypography type="s-body">Knowledge base will be updated with imported files. </WppTypography>
      </div>
      <div data-testid="ag-grid-table ">
        <div className="ag-theme-wpp mb-[32] w-full" key="123">
          <AgGridReact
            ref={gridRef}
            gridId="agency-files-grid"
            rowData={fileList}
            columnDefs={columnDefs}
            loading={isLoading}
            defaultColDef={{
              headerComponent: HeaderCell,
              sortable: false,
            }}
            onRowClicked={({ data, event }) => {
              const target = event?.target as HTMLElement
              if (!target?.dataset?.contextMenu) {
                previewOrDownloadFile(null, data.downloadUrl)
              }
            }}
            rowDragMultiRow
            animateRows
            rowDragManaged
            loadingOverlayComponent={() => <WppSpinner size="m" />}
            rowClass="cursor-pointer"
            rowClassRules={{
              'with-error': data => (data.data ? !!data.data.meta?.error : false),
            }}
            domLayout="autoHeight"
          />
        </div>
      </div>

      <WppModal open={fileId !== null} onWppModalCloseComplete={() => setfileId(null)} size="s">
        <AgencyDeleteFileModal
          fileId={fileId ?? ''}
          handleModalClose={id => {
            if (id) {
              setFileList(fileList.filter(file => file.id !== id))
            }
            setfileId(null)
          }}
        />
      </WppModal>
    </div>
  )
}

export default AgencyFileList
