import {
  WppButton,
  WppEmptyNothingFound,
  WppIconPlus,
  WppSelect,
  WppTypography,
} from '@wppopen/components-library-react'
import { NavigateFunction } from 'react-router-dom'

import { ProjectsFilterGroup } from 'components/ProjectsFilterGroup'
import { noop } from 'utils/common'

import { IEmptyState } from './types'

export default function EmptyState({
  title,
  description,
  action,
  agencyPermission,
  navigate,
}: IEmptyState & { agencyPermission: boolean; navigate: NavigateFunction }) {
  return (
    <div className="px-4">
      <div className="flex justify-start mb-4">
        <WppTypography type="2xl-heading">My Projects</WppTypography>
      </div>
      <div className="flex justify-center mb-4">
        <ProjectsFilterGroup
          navigate={navigate}
          agencyPermission={agencyPermission}
          disabled
          handleApplyFilters={noop}
          handleResetFilters={noop}
          handleCancelFilters={noop}
          handleSearchChange={noop}
          controlValue="none"
          setFilterControlValue={noop}
          numOfSelectedFilters={0}
          resetFiltersVisible={false}
          handleSortFilter={noop}
          activeSortType="name"
          isAsc
          agencyDropdown={
            <WppSelect
              aria-label="Agencies"
              className="w-60"
              title="Agencies"
              placeholder="Agency"
              size="s"
              type="multiple"
              disabled
              withSearch
              withFolder
              value=""
            />
          }
          filters={null}
        />
      </div>
      <div className="flex flex-col gap-6 items-center justify-center w-full h-[65dvh]">
        <WppEmptyNothingFound />
        <div className="flex flex-col gap-2 items-center justify-center">
          <WppTypography type="m-strong">{title}</WppTypography>
          <WppTypography type="m-body">{description}</WppTypography>
        </div>
        {action && (
          <WppButton onClick={action.handleClick} size="m">
            {action.icon ?? <WppIconPlus slot="icon-start" />}
            {action.cta}
          </WppButton>
        )}
      </div>
    </div>
  )
}
