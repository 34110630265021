import { WppButton } from '@wppopen/components-library-react'

import { useDeleteAgencyFile } from 'api/mutations/agencies/useDeleteAgencyFile'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'

export const AgencyDeleteFileModal = ({
  fileId,
  handleModalClose,
}: {
  fileId: string
  handleModalClose: (fileId?: string) => void
}) => {
  const toast = useToast()
  const { mutateAsync: deleteAgencyFile } = useDeleteAgencyFile()

  const handleDeleteConfirm = async () => {
    try {
      await deleteAgencyFile(fileId)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCY_FILES] }).then(() => {
        toast.showToast({
          message: 'File deleted successfully',
          type: 'success',
        })
      })
      handleModalClose(fileId)
    } catch (e) {
      toast.showToast({
        message: 'Error deleting file ',
        type: 'error',
      })
    }
  }

  return (
    <>
      <h3 slot="header">Delete File</h3>
      <p slot="body">
        This will permanently delete this file. This will also delete the content from the knowledge base. Are you sure
        you want to delete this?
      </p>

      <div slot="actions" className="flex flex-row justify-end gap-4">
        <WppButton variant="secondary" onClick={() => handleModalClose()}>
          Cancel
        </WppButton>
        <WppButton variant="destructive" onClick={handleDeleteConfirm}>
          Delete
        </WppButton>
      </div>
    </>
  )
}
