// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fKg3j::part(typography){color:var(--color-font-grey)}.FvWDI::part(textarea):focus{background-color:#fff}.WN9NL{position:absolute;bottom:0}`, "",{"version":3,"sources":["webpack://./src/pages/settings/useCases/useCasesEditAdd/UseCasesEditAdd.module.scss"],"names":[],"mappings":"AAAA,yBACE,4BAAA,CAIA,6BACE,qBAAA,CAIJ,OACE,iBAAA,CACA,QAAA","sourcesContent":[".fileUploadtype::part(typography) {\n  color: var(--color-font-grey);\n}\n\n.textArea::part(textarea) {\n  &:focus {\n    background-color: #ffffff;\n  }\n}\n\n.actionButton {\n  position: absolute;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploadtype": `fKg3j`,
	"textArea": `FvWDI`,
	"actionButton": `WN9NL`
};
export default ___CSS_LOADER_EXPORT___;
