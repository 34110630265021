import { rfiHelperApi } from 'api/index'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export interface Props {
  question_id: string
  questionText?: string | null
  summaryRefId?: string | null
  proposedAnswer?: string | null
}

export const patchRfiQuestionId = ({
  question_id,
  questionText = null,
  proposedAnswer = null,
  summaryRefId = null,
}: Props) =>
  rfiHelperApi.patch<ProgressApiRes>(`/rfi_questions/${question_id}`, {
    questionText,
    summaryRefId,
    proposedAnswer,
  })
