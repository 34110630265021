import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { SortOption, SortOrder } from 'components/users/UsersList'
import { RfiAgency } from 'types/rfis/rfi'
import { IUser, Role } from 'types/users/userList'

export const agenciesAsString = (agencies: RfiAgency[] | undefined) =>
  (agencies &&
    agencies?.reduce((accum, agency, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      accum += `${agency?.name}${!isLastOrFirstOfOnly1 ? ', ' : ''}` || ''
      return accum
    }, '')) ||
  ''

export const useGetPitchTypesByIds = (pitchTypeIds?: string[]) => {
  const { data: pitchTypes } = usePitchTypes()
  return (
    pitchTypeIds &&
    pitchTypes &&
    (pitchTypeIds?.reduce((accum, id, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      const pitchType = pitchTypes?.find(p => p.id === id)?.typeDescription
      if (pitchType) {
        accum += `${pitchType}${!isLastOrFirstOfOnly1 ? ', ' : ''}`
      }
      return accum
    }, '') ||
      '')
  )
}

export const useGetMarketsByIds = (marketIds?: string[], objWithIds = false) => {
  const { data: markets } = useMarkets()
  const { data: regions = [] } = useRegions()
  const allMarkets = [...markets, ...regions]
  if (objWithIds) {
    return (
      (marketIds &&
        allMarkets &&
        marketIds?.reduce<{ market: string; id: string }[]>((accum, id) => {
          const market = allMarkets?.find(m => m.id === id)
          if (market) {
            accum = [...accum, { market: market.name, id }]
          }
          return accum
        }, [])) ||
      []
    )
  }
  return (
    marketIds &&
    allMarkets &&
    (marketIds?.reduce((accum, id, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      const market = allMarkets?.find(m => m.id === id)?.name
      if (market) {
        accum += `${market}${!isLastOrFirstOfOnly1 ? ', ' : ''}`
      }
      return accum
    }, '') ||
      '')
  )
}

export const sortAndSliceUsers = ({
  sortOption,
  users,
  usersCount,
}: {
  sortOption: SortOption
  users: IUser[]
  usersCount: number
}) => {
  const { order, sortBy } = sortOption
  const sortedUsersList = users
    .reduce(acc => {
      if (sortBy === 'Member') {
        return order === SortOrder.ASC
          ? acc.sort((a, b) => a.firstname.toLowerCase().localeCompare(b.firstname))
          : acc.sort((a, b) => b.firstname.toLowerCase().localeCompare(a.firstname))
      }
      if (sortBy === 'Role') {
        return order === SortOrder.ASC
          ? acc.sort((a, b) => a.role.name.toLowerCase().localeCompare(b.role.name))
          : acc.sort((a, b) => b.role.name.toLowerCase().localeCompare(a.role.name))
      }
      return acc
    }, users)
    .slice(0, usersCount)
    .map(usr => ({
      ...usr,
      role: usr.role ?? { id: Math.random().toString(), name: Role.Contributor },
    }))

  return sortedUsersList
}

export const downloadFile = (e: React.MouseEvent<HTMLWppIconDownloadElement, MouseEvent> | null, url: string) => {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank' // Open in a new tab
  link.download = url?.split('/').pop() ?? ''
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const previewOrDownloadFile = async (
  e: React.MouseEvent<HTMLWppIconDownloadElement, MouseEvent> | null,
  url: string,
  fileNameToDownload?: string,
): Promise<boolean> => {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
  const link = document.createElement('a')
  let blob: Blob | null = null
  if (fileNameToDownload !== undefined) {
    link.download = fileNameToDownload
    const response = await fetch(url)
    if (!response.ok) {
      return false
    }
    blob = await response.blob()
  }
  link.href = blob !== null ? URL.createObjectURL(blob) : url
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  return true
}

// temporary fix to get file name until when the backend returns the filenames of usecases
export const getFileNameFromUrl = (url: string) => {
  return url.match(/[ \w-]+?(?=\.pdf)/)
}
