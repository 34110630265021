import { WppListItem } from '@wppopen/components-library-react'
import { ReactNode, useEffect } from 'react'

import style from './ProjectPreviewItem.module.scss'

export const ProjectPreviewItem = ({
  children,
  labelText,
  containerWidth = 0,
  value = '',
}: {
  children?: ReactNode
  labelText: string
  containerWidth: number
  value: string
}) => {
  useEffect(() => {
    /*
     * used to set the width of the list item so tooltip can be displayed if necessary
     */
    const items: HTMLElement[] = Array.from(document.querySelectorAll('[data-card-list-item]'))
    const width = `${containerWidth}px`
    if (items.length > 0) {
      items.forEach(item => {
        if (item && item.shadowRoot) {
          ;(item?.shadowRoot?.host as HTMLElement)?.style?.setProperty('--li-width', width)
        }
      })
    }
  }, [containerWidth])

  return (
    <WppListItem data-card-list-item className={style.listItem}>
      <p slot="label" className="w-full">
        <span className={style.itemLabel}>
          {labelText}: {'  '}
        </span>
        {children && (
          <>
            {children}
            {'  '}
          </>
        )}
        <span className={style.itemValue}>{value}</span>
      </p>
    </WppListItem>
  )
}
