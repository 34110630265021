import { WppBreadcrumb, WppIconChevron, WppSpinner } from '@wppopen/components-library-react'
import { useNavigate, useParams } from 'react-router-dom'

import { useUseCase } from 'api/queries/use-cases/useUseCase'

import { UseCaseDetailsEditView } from './UseCaseDetailsEditView'

export const UseCasesContainer = () => {
  const navigate = useNavigate()
  const { useCaseId } = useParams()

  const { data: useCase, isLoading } = useUseCase({ params: { id: useCaseId || '' } })

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[
            { label: 'Back to Project Dashboard', path: '/' },
            { label: 'Settings', path: '/rfi-helper-tool/settings?view=usecases' },
            { label: `${useCase?.title || 'Use case'}`, path: `/settings/use-cases/${useCase?.id}` },
          ]}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <WppSpinner size="l" />
        </div>
      ) : (
        <>
          <UseCaseDetailsEditView useCase={useCase} />
        </>
      )}
    </div>
  )
}
