import { WppIconFile, WppSpinner, WppTag, WppTypography } from '@wppopen/components-library-react'
import { useMemo } from 'react'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useClients } from 'api/queries/clients/useClients'
import { useUseCase } from 'api/queries/use-cases/useUseCase'
import { downloadFile, getFileNameFromUrl } from 'utils/projectUtils'

export const UseCasesDetails = ({ useCaseId }: { useCaseId: string }) => {
  const { data: useCase, isFetching } = useUseCase({ params: { id: useCaseId || '' }, refetchOnWindowFocus: true })
  const { data: clients = [] } = useClients()
  const { data: agencies = [] } = useGetAgencies()

  const {
    description,
    fileName,
    fileSize,
    summary,
    agencyIds,
    markets,
    client,
    pitchTypes,
    subCategory,
    isConfidential,
    fileUrl,
  } = useCase

  const agencyNames = useMemo(() => {
    return agencies.filter(agency => agencyIds?.includes(agency.id)).map(agency => agency.name)
  }, [agencies, agencyIds])

  if (isFetching) return <WppSpinner size="s" />
  const size = fileSize ? Number(fileSize / (1000 * 1000)).toFixed(2) + 'MB' : ''
  const file = fileName ? fileName : getFileNameFromUrl(fileUrl) || ''
  return (
    <>
      {description && (
        <div>
          <WppTypography type="s-body">{description}</WppTypography>
        </div>
      )}
      <div>
        {isConfidential ? (
          <WppTag label="Confidential file" variant="warning" />
        ) : (
          <WppTag label="Non-Confidential file" variant="warning" />
        )}
      </div>

      {[
        { label: 'Agency', value: agencyNames.join(', ') },
        { label: 'Market', value: markets.join(', ') },
        { label: 'Client', value: clients.find(el => el.id === client)?.name || client },
        { label: 'Pitch Types', value: pitchTypes.join(', ') },
        { label: 'Subcategory', value: subCategory },
      ].map((item, idx) => (
        <div className="flex gap-2" key={`${item.label}-${idx}`}>
          <WppTypography className="text-grey" type="s-strong">
            {item.label}:
          </WppTypography>
          <WppTypography type="s-body">{item.value}</WppTypography>
        </div>
      ))}

      <WppTypography type="xl-heading">Uploaded Files</WppTypography>
      <div
        className="flex gap-2 bg-[#F4F5F7] rounded-6 p-3 cursor-pointer"
        // @ts-ignore
        onClick={e => downloadFile(e, useCase.fileUrl)}
      >
        <WppIconFile />
        <WppTypography type="s-body" className="underline text-[#343A3F]">
          {/*replace with file name from api after it's updated*/}
          {file}
          {'  '}
        </WppTypography>
        <WppTypography type="s-body" className="text-[#697077]">
          {/* file size should be from the api */}
          {size}
        </WppTypography>
      </div>

      <div>
        <WppTypography className="text-grey mb-2" type="s-strong">
          LLM Generated Summary:
        </WppTypography>
        <WppTypography type="s-body">{summary}</WppTypography>
      </div>
    </>
  )
}
