import { WppSpinner } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { Navigate, Outlet } from 'react-router-dom'

import { useFetchUserPermissions } from 'api/queries/users/useFetchUserPermissions'
import { agencyRoles } from 'constants/agencyRoles'

export const SettingsRoutes = () => {
  const { osContext } = useOs()
  const { data: userPermissions, isLoading } = useFetchUserPermissions({
    params: {
      account_ids: [osContext.tenant.azMeta.organizationsId],
    },
  })

  const allPermissions = userPermissions?.map(permission => permission.permissions)
  const hasPermission = allPermissions?.flat().includes(agencyRoles.RFI_AGENCY_MANAGE)

  if (isLoading) {
    return (
      <div className="mx-auto">
        <WppSpinner size="m" />
      </div>
    )
  }
  return hasPermission ? <Outlet /> : <Navigate to="/" />
}
