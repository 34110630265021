import { WppActionButton, WppIconSend, WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import styles from './InputActions.module.scss'

const QUESTION_MAX_LENGTH = 2000

interface InputActionsProps {
  question: string
  answerIsLoading: boolean
  onSubmit: () => void
  disabled?: boolean
}

const questionMaxLength = QUESTION_MAX_LENGTH

const InputActions = ({ question, answerIsLoading, onSubmit, disabled }: InputActionsProps) => {
  const [sendButtonColor, setSendButtonColor] = useState('var(--color-font-greyIcon)')

  useEffect(() => {
    const color =
      question.length > 0 && question.length <= questionMaxLength && !disabled ? 'var(--color-font-grey)' : '#A2A9B0'
    setSendButtonColor(color)
  }, [disabled, question.length])

  return (
    <div
      className={clsx(styles.inputActions, disabled && 'bg-[#F8F9FB]', 'flex flex-row justify-between items-center')}
    >
      <div className="flex flex-row items-center">
        {/* <WppActionButton disabled onClick={attachButtonAction}>
          <WppIconAttach />
        </WppActionButton>
        <WppActionButton disabled onClick={imageButtonAction}>
          <WppIconImage />
        </WppActionButton>
        <WppActionButton disabled onClick={micButtonAction}>
          <WppIconMicOn />
        </WppActionButton> */}
      </div>
      <div className="flex flex-row items-center">
        <WppTypography
          color={sendButtonColor}
          type="xs-body"
          className={clsx(
            question.length > questionMaxLength
              ? styles.questionCharacterLimitLabelError
              : styles.questionCharacterLimitLabel,
          )}
        >
          &nbsp;{question.length}/{questionMaxLength}
        </WppTypography>
        <WppActionButton
          type="button"
          onClick={() => onSubmit()}
          className={clsx(styles.submitQuestionButton, 'cancel-drag')}
          disabled={answerIsLoading || question.length === 0 || question.length > questionMaxLength || disabled}
        >
          <WppIconSend size="m" color={sendButtonColor} />
        </WppActionButton>
      </div>
    </div>
  )
}

export default InputActions
