import { groupBy, prop } from 'ramda'

import { ConvertedQuestion } from 'components/textEditor/types'
import { RfiQuestion } from 'types/rfis/rfi'

export const convertQuestionsToCategories = groupBy<RfiQuestion>(prop('rfiCategoryId'))

export const groupAndConvertQuestions = (questions: RfiQuestion[]) => {
  const groupedQuestions = convertQuestionsToCategories(questions)

  const result = Object.keys(groupedQuestions)
    .map(categoryId => {
      const categoryQuestions = groupedQuestions[categoryId]
      const { rfiCategoryName } = categoryQuestions![0]

      return {
        id: categoryId.toString(),
        name: rfiCategoryName,
        questions:
          categoryQuestions
            ?.map(q => ({
              ...q,
              id: q.id.toString(),
            }))
            // filter out any child questions
            .filter(q => !q?.parentId) || [],
      } as ConvertedQuestion
    }) // filter out any empty categories
    .filter(c => c?.id !== null)
  return result
}
