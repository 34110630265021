import { rfiHelperApi } from 'api'
import { ClientType } from 'types/clients/client'

export const fetchClients = () =>
  rfiHelperApi.get<ClientType[]>('/clients', {
    params: {
      page: 1,
      itemsPerPage: 100,
    },
  })
