import {
  WppActionButton,
  WppAvatar,
  WppIconDocument,
  WppIconMore,
  WppListItem,
  WppMenuContext,
} from '@wppopen/components-library-react'
import { ColDef } from 'ag-grid-community'

import { TableDataItem } from './AgencyFileList'
import { bytesToMB } from './utils'

export const columnDefenitions = (
  actionsAllowed = false,
  handleDownloadFileClick: (url: string, fileName: string) => void,
  handleDeleteFileClick: (id: string) => void,
): ColDef[] => [
  {
    field: 'name',
    sortable: true,
    headerName: 'Name',
    flex: 1.5,
    rowDrag: false,
    cellClass: 'truncate',
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-row justify-start items-center gap-2 truncate">
        <WppIconDocument color="#4D5358" /> <div>{data.name}</div>
      </div>
    ),
  },
  {
    field: 'size',
    sortable: true,
    headerName: 'Size',
    flex: 1,

    maxWidth: 100,
    comparator: (a: number, b: number) => a - b,
    cellRenderer: ({ data }: { data: TableDataItem }) => <div className="text-right">{bytesToMB(data.size)}</div>,
  },
  {
    field: 'createdAt',
    sortable: true,
    headerName: 'Added',
    flex: 1,
    maxWidth: 120,
    comparator: (a: string, b: string) => {
      return new Date(a).getTime() - new Date(b).getTime()
    },
    cellRenderer: ({ data }: { data: TableDataItem }) =>
      new Intl.DateTimeFormat('en-GB').format(data.createdAt ? new Date(data.createdAt) : new Date()), // add data.createdAt when BE is implemented
  },
  {
    field: 'createdBy',
    headerName: 'Uploaded By',
    sortable: true,
    //comparator: (a: Agency['createdBy'], b: Agency['createdBy']) => a?.name?.localeCompare(b.name || ''),
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-row justify-start items-center gap-4 truncate">
        {/* TODO add data.user when BE is implemented */}
        {data.createdBy && (
          <WppAvatar
            size="m"
            className="max-w-8 max-h-8 flex-shrink-0 flex-grow-0 flex-auto"
            withTooltip
            name={data.createdBy?.name || ''}
            src={data.createdBy?.img || ''}
          />
        )}
        <i>{data?.createdBy?.name || ''}</i>
      </div>
    ),
    flex: 1,
  },

  {
    headerName: '',
    field: 'Actions',
    width: 100,
    hide: !actionsAllowed,
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-col justify-center h-full">
        <WppMenuContext
          data-context-menu
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <WppActionButton
            slot="trigger-element"
            data-context-menu
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <WppIconMore
              direction="horizontal"
              data-context-menu
              onClick={e => {
                e.stopPropagation()
              }}
            />
          </WppActionButton>
          <div>
            <WppListItem onWppChangeListItem={() => handleDownloadFileClick(data.downloadUrl, data.name)}>
              <p slot="label">Download</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => handleDeleteFileClick(data.id)}>
              <p slot="label">Delete</p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>
    ),
  },
]
