import { createUseQuery } from 'api/common/createUseQuery'
import { fetchTaskStatus, fetchTaskStatuses } from 'api/fetchers/task-status/taskStatus'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useTasksStatus = createUseQuery({
  queryKey: ApiQueryKeys.TASKS_STATUS,
  fetcher: fetchTaskStatus,
  selector: res => res?.data ?? ({} as ProgressApiRes),
})

export const useTasksStatuses = createUseQuery({
  queryKey: ApiQueryKeys.TASKS_STATUS,
  fetcher: fetchTaskStatuses,
  selector: res => res?.data ?? ([] as ProgressApiRes[]),
})

/*
export const useMockTasksStatus = createUseQuery({
  queryKey: ApiQueryKeys.TASKS_STATUS,
  fetcher: mockFetchTaskStatus as unknown as ({
    taskId,
  }: {
    taskId: string
  }) => CancelableRequestProducer<ProgressApiRes>,
  selector: res => res?.data ?? ({} as ProgressApiRes),
})
*/
