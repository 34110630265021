import { rfiHelperFormApi } from 'api'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export interface AddUseCaseParams {
  params: {
    file_key: string
    id?: string
    title: string
    client: string
    markets: string[]
    pitch_types: string[]
    sub_category: string
    agencyIds: string[]
    isConfidential: boolean
    description?: string
  }
  formData?: FormData
}

export const createUseCase = (data: AddUseCaseParams) => {
  const params = new URLSearchParams()
  params.append('title', data.params.title)
  params.append('file_key', data.params.file_key)
  params.append('client', encodeURIComponent(data.params.client))

  params.append('sub_category', encodeURIComponent(data.params.sub_category))

  params.append('isConfidential', encodeURIComponent(data?.params?.isConfidential.toString()))
  params.append('description', data.params.description || '')

  let finalParams = params.toString()

  console.log('description', data.params.description)

  data.params.pitch_types.forEach(el => {
    finalParams += `&pitch_types=${encodeURIComponent(el)}`
  })

  data.params.markets.forEach(el => {
    finalParams += `&markets=${encodeURIComponent(el)}`
  })

  finalParams += `&agencyIds=${encodeURIComponent(data.params.agencyIds.join(','))}`

  if (data?.formData) {
    return rfiHelperFormApi.post<ProgressApiRes>(`/use-cases?${finalParams}`, data.formData)
  }

  return rfiHelperFormApi.post<ProgressApiRes>(`/use-cases?${finalParams}`)
}
