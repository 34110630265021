/* eslint-disable react-hooks/exhaustive-deps */
import { WppButton, WppDivider, WppModal, WppSpinner, WppTypography } from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAddRfiChildQuestion } from 'api/mutations/rfis/childQuestions/useAddRfiChildQuestion'
import { useDeleteComment } from 'api/mutations/rfis/comments/useDeleteComment'
import { usePatchRfiQuestionId } from 'api/mutations/rfis/usePatchRfiQuestionId'
import { useRfiQuestionId } from 'api/queries/rfis/useRfiQuestionId'
import { useTasksStatus, useTasksStatuses } from 'api/queries/task-status/useTasksStatus'
import { useFetchRfiMembers } from 'api/queries/users/useFetchRfiMembers'
import { queryClient } from 'app/Root'
import { LoaderProgressWithDescription, ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useRfiQuestionContext from 'hooks/useRfiQuestionContext'
import useTaskContext from 'hooks/useTaskContext'
import { useToast } from 'hooks/useToast'
import { RfiComment, RfiCommentState, RfiQuestion } from 'types/rfis/rfi'
import { Role } from 'types/users/userList'
import { ANALYTICS_EVENTS, trackAnalytics } from 'utils/analytics'

import styles from './Chat.module.scss'
import { CommentAction, ConversationMessage } from './conversation-message/ConversationMessage'
import { QuestionInputNew } from './question-input/QuestionInputNew'
import { MessageType, ProjectActionType } from './types'
import {
  hasRequiredPermission,
  mergeQuestions,
  transformComments,
  updateMergedConversation,
  updateQuestionsCache,
} from './utils'

export interface Message {
  id: string
  type: MessageType
  content: string
  timestamp: string
  rfiQuestionId?: string
  parentId?: string
  comments: RfiComment[] | []
  commentState?: RfiCommentState
}

export default function ChatCmp({ addNewQuestion }: { addNewQuestion: (questionText?: string) => void }) {
  const {
    osContext: {
      userDetails: { email: currentUserEmail },
    },
  } = useOs()
  const rfiContext = useRfiQuestionContext()
  const { rfiQuestionId } = rfiContext
  const { tasks, setTasks } = useTaskContext()

  const params = useParams()
  const { data: rfiQuestion, isLoading } = useRfiQuestionId({
    params: {
      question_id: rfiQuestionId ?? '',
    },
  })
  const refScrollBottomDiv = useRef<HTMLDivElement>(null)
  const [conversation, setConversation] = useState<Message[]>([])
  const [question, setQuestion] = useState('')
  const [flatQuestions, setFlatQuestions] = useState<RfiQuestion[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [commentToDelete, setCommentToDelete] = useState<{ messageId: string; commentId: string } | null>(null)

  const { showToast } = useToast()
  const { data: rfiMembers } = useFetchRfiMembers({
    params: {
      rfi_id: params.projectId!,
    },
  })
  const projectMember = rfiMembers?.filter(member => member.memberDetail.email === currentUserEmail)?.[0]
  const canGenerateAnswers = hasRequiredPermission(projectMember?.role, [ProjectActionType.GENERATE_ANSWERS])

  const { mutateAsync: createChildQuestion, isPending: isPendingChildQuestion } = useAddRfiChildQuestion()
  const { mutateAsync: editProject, isPending: isPendingRfiQuestion } = usePatchRfiQuestionId()

  const isPending = isPendingChildQuestion || isPendingRfiQuestion
  /* polling the task status endpoint for child questions of an RFI question */
  const [childQuestionTask, setChildQuestionTask] = useState<ProgressApiRes | null>(null)
  const { data: childTask } = useTasksStatus({
    params: { taskId: childQuestionTask?.id ?? '' },
    enabled: !!childQuestionTask,
    refetchInterval: 2000,
  })
  /* end child task polling */

  const { data: taskStatuses } = useTasksStatuses({
    params: {
      taskIds: tasks?.reduce((acc: string[], task) => {
        if (!!task.id && !task.completed) {
          acc.push(task.id)
        }
        return acc
      }, []),
    },
    enabled: tasks?.length > 0,
    refetchInterval: 2000,
  })

  const { mutateAsync: deleteComment, isPending: isDeletingComment } = useDeleteComment({
    onSuccess: () => {
      setIsModalOpen(false)
      showToast({
        type: 'success',
        message: 'Comment deleted successfully',
      })
      updateQuestionComments({ action: 'delete', messageId: commentToDelete?.messageId! })
    },
  })

  useEffect(() => {
    if (rfiQuestion?.childQuestions && rfiQuestionId !== null) {
      const convo = mergeQuestions(rfiQuestion)
      setFlatQuestions(convo)
      setConversation(updateMergedConversation(convo) as Message[])
      scrollToBottom()
    }
  }, [rfiQuestion, rfiQuestionId])

  const scrollToBottom = () => {
    const scrollImmediateTimeout = setTimeout(() => {
      if (conversation) {
        refScrollBottomDiv.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }, 10)

    return () => {
      clearTimeout(scrollImmediateTimeout)
    }
  }

  const updateQuestionComments = ({
    commentData,
    action,
    messageId,
  }: {
    commentData?: Partial<RfiComment>
    action: CommentAction
    messageId: string
  }) => {
    const updatedQuestionWithComments = conversation.map(question => {
      if (action === 'add' && messageId === question.id) {
        return { ...question, comments: [...question.comments, commentData] }
      }
      if (action === 'edit' && messageId === question.id) {
        return {
          ...question,
          comments: question.comments.map(item =>
            item.id === commentData!.id ? { ...commentData, isEditMode: false } : item,
          ),
        }
      }
      if (action === 'delete' && messageId === question.id) {
        return {
          ...question,
          commentState:
            question.comments.length === 1 ? { showComments: false, showInput: false } : question.commentState,
          comments: question.comments.filter(item => item.id !== commentToDelete?.commentId!),
        }
      }
      return question
    })
    setConversation(updatedQuestionWithComments as Message[])
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI, ApiQueryKeys.RFI_QUESTION] })
  }

  const handleEditProject = async (questionText: string) => {
    try {
      const isQuestionInProgress = tasks.find(task => task.resultObjectId === rfiQuestionId && task.completed === false)
      if (!isQuestionInProgress) {
        /* 
        set tasks initial state and update it after the question is submitted
        we need to set this before the request fires
        so we can disable the other questions when 2 requests are flight
        */
        setTasks(prev => [...prev, { resultObjectId: rfiQuestionId || '' } as ProgressApiRes])
        const res = await editProject({
          question_id: rfiQuestionId || '',
          questionText: questionText,
        })

        setTasks(prev =>
          prev.map(task =>
            task.resultObjectId === rfiQuestionId ? { ...res.data, resultObjectId: task.resultObjectId } : task,
          ),
        )
        setQuestion('')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleEditProjectChild = async (questionText: string, parentId = rfiQuestionId) => {
    if (!rfiQuestionId) {
      showToast({
        type: 'error',
        message: 'Error adding query to conversation - no parentId found. Please try again.',
      })
    } else {
      createChildQuestion({
        questionText: questionText,
        parentId: parentId || '',
      })
        .then(res => {
          const response = { ...res.data, resultObjectId: rfiQuestionId || '' }
          setTasks(prev => [...prev, response])
          setChildQuestionTask(response)
        })
        .then(() => setQuestion(''))
        .catch(() => {
          showToast({
            message: 'Error adding a question to the conversation',
            type: 'error',
          })
        })
    }
  }

  const handleDeleteComment = async () => {
    try {
      await deleteComment({
        commentId: commentToDelete?.commentId ?? '',
      })
    } catch (e) {
      showToast({
        message: (e as any).toString(),
        type: 'error',
      })
    }
  }

  const handleSubmitQuestion = async (
    questionValue: string,
    isChildQuestion: boolean = true,
    parentId = rfiQuestionId,
  ) => {
    setQuestion('')
    setConversation(conversationDraft => {
      const newMessage = {
        id: `1-${MessageType.QUESTION}`,
        type: MessageType.QUESTION,
        content: questionValue,
        comments: [],
        timestamp: new Date().toISOString(),
      }
      return [...conversationDraft, newMessage]
    })

    /*
     * we're assuming that if a question has no Id
     * then it's a new question and we should add it to the conversation
     */
    if (!rfiQuestionId) {
      addNewQuestion(questionValue)
      return
      /*
       * ...and continuing with the conditional checks here
       */
    } else if (isChildQuestion && parentId) {
      handleEditProjectChild(questionValue, parentId)
    } else {
      handleEditProject(questionValue)
    }
    scrollToBottom()
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: ANALYTICS_EVENTS.POST_QUERY,
    })
  }

  const handleQuestionInput = setQuestion

  const taskFromContext = tasks.find(task => task.resultObjectId === rfiQuestionId)
  const taskFromApi = taskStatuses.find(task => task.id === taskFromContext?.id)
  const isTaskRunning = taskFromApi?.completed === false

  // update tasks context when question is selected
  useEffect(() => {
    if (rfiQuestionId && taskFromApi?.completed) {
      setTasks(prev => prev.filter(task => task.resultObjectId !== rfiQuestionId))
      // queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI, { question_id: rfiQuestionId }] })
      return
    }
    if (rfiQuestionId && taskFromApi && !taskFromApi.completed) {
      setTasks(prev =>
        prev.map(task =>
          task.resultObjectId === rfiQuestionId
            ? { ...task, ...taskFromApi, resultObjectId: taskFromContext?.resultObjectId! }
            : task,
        ),
      )
    }
  }, [rfiQuestion, taskFromApi])

  useEffect(() => {
    if (rfiQuestion?.id === rfiQuestionId) {
      setConversation([])
    }
  }, [rfiQuestion?.id])

  useEffect(() => {
    if (rfiQuestion?.proposedAnswer) {
      updateQuestionsCache({ proposedAnswer: rfiQuestion?.proposedAnswer, queryClient, rfiQuestionId: rfiQuestionId! })
      if (conversation.length % 2 === 1) {
        setConversation(conversationDraft => {
          const newMessage = {
            id: `${rfiQuestion.id}-${MessageType.ANSWER}`,
            type: MessageType.ANSWER,
            content: rfiQuestion.proposedAnswer,
            comments: transformComments(rfiQuestion.answerComments),
            timestamp: new Date().toISOString(),
          }
          return [...conversationDraft, newMessage]
        })
        scrollToBottom()
      }
    }
  }, [rfiQuestion?.proposedAnswer])

  useEffect(() => {
    /* init */
    if (conversation.length === 0 && rfiQuestionId && !question) {
      if (rfiQuestion?.proposedAnswer && rfiQuestion?.questionText) {
        const answer = {
          id: `${rfiQuestion.id}-${MessageType.ANSWER}`,
          type: MessageType.ANSWER,
          content: rfiQuestion.proposedAnswer,
          comments: transformComments(rfiQuestion.answerComments),
          timestamp: new Date().toISOString(),
        }
        const question = {
          id: `${rfiQuestion.id}-${MessageType.QUESTION}`,
          type: MessageType.QUESTION,
          content: rfiQuestion.questionText,
          comments: transformComments(rfiQuestion.questionComments),
          timestamp: new Date().toISOString(),
        }
        setConversation([question, answer])
        return
      }
      if (rfiQuestion?.questionText && !rfiQuestion?.proposedAnswer && canGenerateAnswers) {
        //  init with question
        handleSubmitQuestion(rfiQuestion.questionText, false)
        return
      }
    }
  }, [conversation, rfiQuestionId, question])

  useEffect(() => {
    if (flatQuestions.length > 0) {
      setConversation(updateMergedConversation(flatQuestions) as Message[])
      scrollToBottom()
    }
  }, [flatQuestions])

  /*
    update conversations if user cannot generate answers
    ie if the user has role Viewer or Commenter
  */
  useEffect(() => {
    if (!rfiQuestion?.proposedAnswer && !isTaskRunning && !canGenerateAnswers && !conversation?.[1]?.content) {
      const answer = {
        id: `${rfiQuestion?.id}-${MessageType.ANSWER}`,
        type: MessageType.ANSWER,
        content: 'This question has not been answered yet',
        comments: [],
        timestamp: new Date().toISOString(),
      }
      setConversation(conversationDraft =>
        conversationDraft.map(message => {
          if (message.type === MessageType.ANSWER) {
            return answer
          }
          return message
        }),
      )
    }
  }, [rfiQuestion?.proposedAnswer, isTaskRunning, conversation?.[1]?.content, canGenerateAnswers])

  useEffect(() => {
    /* polling the task status of questions asked in chat of an existing rfi question */
    if (childTask && childTask.completed) {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI_QUESTION, { question_id: rfiQuestionId }] })
      setChildQuestionTask(null)
    }
  }, [childTask])

  return (
    <>
      <div
        className={clsx(
          styles.chatContainer,
          'flex flex-col gap-2 rounded-[10px] w-full max-w-[650px] h-full justify-between',
        )}
      >
        {conversation.length > 0 && conversation[0].type === MessageType.QUESTION && conversation[0].content && (
          <div>
            <WppTypography type="m-strong">{conversation[0].content}</WppTypography>
            <WppDivider />
          </div>
        )}

        {!conversation.length && !rfiQuestionId && (
          <div className="flex flex-row flex-grow-[2] justify-center items-center text-center text-gray-800">
            <WppTypography type="s-strong">
              Select a Suggested Question on the Left to get Proposed Answer
              {hasRequiredPermission(projectMember?.role, [ProjectActionType.ASK_RFI_QUESTION])
                ? ', or Type Manually'
                : ''}
            </WppTypography>
          </div>
        )}

        <div className={clsx('flex flex-col justify-between gap-4', conversation.length && 'flex-grow-[2]')}>
          <div className={clsx(styles.chatDisplay, !conversation.length && 'flex flex-col gap-3')}>
            {conversation.map(message => (
              <div
                key={`${message.id}-${message.timestamp}`}
                className={clsx(message.type === MessageType.QUESTION && message.comments.length > 0 && 'mb-10')}
              >
                <ConversationMessage
                  message={message}
                  isTaskRunning={isTaskRunning}
                  setConversation={setConversation}
                  projectMembers={rfiMembers}
                  question={rfiQuestion}
                  handleDeleteComment={({ messageId, commentId }) => {
                    setCommentToDelete({ messageId, commentId })
                    setIsModalOpen(true)
                  }}
                  handleUpdateCommentList={updateQuestionComments}
                />
              </div>
            ))}
            {isTaskRunning && (
              <div className={clsx('flex flex-row items-start justify-center py-4 bg-[#f3f3f3] rounded mt-6')}>
                <LoaderProgressWithDescription taskStatus={taskFromContext ?? null} />
              </div>
            )}
            <div ref={refScrollBottomDiv} />
          </div>

          {(projectMember?.role === Role.Owner ||
            (hasRequiredPermission(projectMember?.role, [ProjectActionType.ASK_RFI_QUESTION]) &&
              conversation.length > 0)) && (
            <div className={clsx(styles.questionInputWrapper)}>
              <div className={styles.inputBorder}>
                <QuestionInputNew
                  onInput={handleQuestionInput}
                  disabled={isLoading || isPending || isTaskRunning}
                  question={question}
                  answerIsLoading={isPending}
                  handleSubmit={() => handleSubmitQuestion(question, true, rfiQuestionId)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <WppModal open={isModalOpen}>
        <h3 slot="header">Delete Comment</h3>
        <p slot="body">Are you sure you want to permanently delete this comment?</p>
        <div slot="actions" className="flex flex-row justify-end gap-4">
          <WppButton variant="secondary" onClick={() => setIsModalOpen(false)} disabled={isDeletingComment}>
            Cancel
          </WppButton>
          <WppButton
            variant="destructive"
            disabled={isDeletingComment}
            onClick={() => {
              handleDeleteComment()
            }}
          >
            {isDeletingComment ? <WppSpinner color="#fff" /> : 'Delete'}
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}
