import { TabsChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppBreadcrumb,
  WppButton,
  WppIconChevron,
  WppIconEdit,
  WppSpinner,
  WppTab,
  WppTabs,
  WppTypography,
} from '@wppopen/components-library-react'
import { useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import { useGetAgency } from 'api/queries/agencies/useGetAgency'
import { AgencyDetails } from 'pages/agency-dashboard/AgencyDetails'
import { EditAddAgency } from 'pages/agency-dashboard/EditAddAgency'

import style from './agencyEditDetail.module.scss'
import { AgencyEditKnowledgeBase } from './AgencyEditKnowledgeBase'

export const AgencyEditDetail = () => {
  const [isEditmode, setIsEditmode] = useState(false)

  const [currentTab, setCurrentTab] = useState('details')
  const { agencyId } = useParams()
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  const { data: agency, isLoading } = useGetAgency({ params: { id: agencyId! } })

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }

  const handleEditButtonClick = () => {
    setCurrentTab('details')
    setIsEditmode(prev => !prev)
  }

  const view = locationState?.toggleView
  const dashPath = `/rfi-helper-tool/settings${view ? '?view=' + view : ''}`

  if (isLoading) {
    return (
      <div className="h-[10dvh] flex justify-center items-center">
        <WppSpinner size="m" />
      </div>
    )
  }
  if ((!isLoading && !agencyId) || !agency)
    return <div> Sorry that id isn't recognised, so we're unable to find the agency.</div>

  return (
    <div>
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[
            { label: 'Project Dashboard', path: '/' },
            {
              label: 'Settings',
              path: dashPath,
            },
            { label: 'Agency Detail', path: `/rfi-helpder-tool/settings/settings/agency-dashboard/${agencyId}` },
          ]}
        />
      </div>

      <div className="flex justify-between items-center mb-4" data-is-edit={isEditmode}>
        <WppTypography type="xl-heading" className={isEditmode && currentTab === 'details' ? style.hideHeading : ''}>
          {agency.name}
        </WppTypography>

        <WppButton variant="primary" className={style.editButton} onClick={handleEditButtonClick}>
          <div className="flex items-center gap-2">
            <WppIconEdit color="white" size="s" />
            <WppTypography type="s-strong" className="text-white">
              Edit
            </WppTypography>
          </div>
        </WppButton>
      </div>
      <WppTabs value={currentTab} onWppChange={handleTabChange} size="s">
        <WppTab className="w-24" value="details">
          Details
        </WppTab>
        <WppTab className="w-40" value="knowledgeBase">
          Knowledge Base
        </WppTab>
      </WppTabs>
      {
        {
          details: (
            <div className="flex flex-col gap-6">
              {isEditmode ? (
                <EditAddAgency
                  agency={agency}
                  handleSave={() => setIsEditmode(false)}
                  handleCancel={() => setIsEditmode(false)}
                />
              ) : (
                <AgencyDetails agency={agency} />
              )}
            </div>
          ),
          knowledgeBase: <AgencyEditKnowledgeBase agency={agency} />,
        }[currentTab]
      }
    </div>
  )
}
