import { WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'

import { Agency } from 'types/agencies/agencies'

import style from './agencyDetails.module.scss'
import AgencyFileList from './EditAddAgency/agencyFileList/AgencyFileList'

export const AgencyDetails = ({ agency }: { agency: Agency }) => {
  return (
    <div className={clsx('flex flex-col gap-5', style.tab)}>
      <div>
        <WppTypography type="s-body">{agency.description}</WppTypography>
      </div>

      <AgencyFileList agencyId={agency.id} />
    </div>
  )
}
