import { rfiHelperApi } from 'api'
import { UseCase } from 'types/use-cases/useCase'

export const fetchUseCases = ({
  rfiId,
  agencyIds,
  pitchTypeIds,
}: {
  rfiId?: string
  agencyIds?: string[]
  pitchTypeIds?: string[]
}) =>
  rfiHelperApi.get<UseCase[]>('/use-cases', {
    params: {
      rfiId,
      agencyIds: agencyIds?.join(','),
      pitchTypeIds: pitchTypeIds?.join(','),
    },
    paramsSerializer: { indexes: null },
  })
