// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-is-edit=true] .ftGNw,[data-is-edit=true] .wHCTS{display:none}.DywGZ{margin-top:var(--sizes-spacing-16)}[data-is-edit=true] .DywGZ{margin-top:0}`, "",{"version":3,"sources":["webpack://./src/pages/settings/useCases/useCasesDetailsEditVeiw.module.scss"],"names":[],"mappings":"AAGA,sDACI,YAAA,CAGJ,OACI,kCAAA,CAEJ,2BACI,YAAA","sourcesContent":[".UseCaseDetailsEditView{\n    \n}\n[data-is-edit=\"true\"] .editButton, [data-is-edit=\"true\"] .title{\n    display: none;\n}\n\n.tab{\n    margin-top: var(--sizes-spacing-16);\n}\n[data-is-edit=\"true\"] .tab{\n    margin-top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editButton": `ftGNw`,
	"title": `wHCTS`,
	"tab": `DywGZ`
};
export default ___CSS_LOADER_EXPORT___;
