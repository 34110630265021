import { useOs } from '@wppopen/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { rfiHelperApi, rfiHelperFormApi, masterDataApi, userDataApi, facadeApi, gcpApi } from 'api'
import { ApiInstance } from 'api/common/createApi'

const apiInstances = [gcpApi, rfiHelperApi, rfiHelperFormApi, masterDataApi, userDataApi, facadeApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { osApi, osContext } = useOs()

  const tenantId = osContext.tenant.id

  const getInterceptorToEjectRfiApi = (instance: ApiInstance) => {
    return instance.client.interceptors.request.use(
      instanceConfig => {
        const bearer = osApi.getAccessToken()
        if (bearer) {
          instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
        }

        if (tenantId) {
          instanceConfig!.headers!['X-Tenant-Id'] = tenantId
        }
        return instanceConfig
      },
      error => Promise.reject(error),
    )
  }

  const getInterceptorToEjectGcpFileUpload = (instance: ApiInstance) => {
    return instance.client.interceptors.request.use(
      instanceConfig => {
        instanceConfig!.headers!['Content-Type'] = 'application/pdf'
        instanceConfig!.headers!['x-goog-content-length-range'] = '1,1000000000'

        return instanceConfig
      },
      error => Promise.reject(error),
    )
  }

  useEffect(() => {
    const interceptors = apiInstances.map((instance, index) => {
      const interceptorToEject =
        index === 0 ? getInterceptorToEjectGcpFileUpload(instance) : getInterceptorToEjectRfiApi(instance)

      return interceptorToEject
    })

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}
