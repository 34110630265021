// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shTPZ{margin-top:var(--sizes-spacing-16)}`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/agencyDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,kCAAA","sourcesContent":[".tab {\n  margin-top: var(--sizes-spacing-16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `shTPZ`
};
export default ___CSS_LOADER_EXPORT___;
