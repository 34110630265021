// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VoajH::part(textarea){border:0px}.VoajH::part(textarea):not(:disabled):hover{background:#fff}`, "",{"version":3,"sources":["webpack://./src/components/chat/question-input/QuestionInput.module.scss"],"names":[],"mappings":"AAGE,uBACE,UAAA,CACA,4CACE,eAAA","sourcesContent":["@import '@wppopen/components-library/dist/collection/scrollbar';\n\n.input {\n  &::part(textarea) {\n    border: 0px;\n    &:not(:disabled):hover {\n      background: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `VoajH`
};
export default ___CSS_LOADER_EXPORT___;
