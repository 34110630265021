import { AnalyticsActionType } from '@wppopen/core'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAddSummaryQuestion } from 'api/mutations/summaries/useAddSummaryQuestion'
import { useRfi } from 'api/queries/rfis/useRfi'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { ANALYTICS_EVENTS, trackAnalytics } from 'utils/analytics'

import { SummaryAndChat } from './SummaryAndChat'

export interface SummaryRef {
  scrollToTopDivRef: HTMLDivElement | null
  scrollToBottomDivRef: HTMLDivElement | null
  summaryContainerRef: HTMLDivElement | null
}

export default function RfiSummaryPage() {
  const refs = useRef<SummaryRef>(null)
  const [task, setTask] = useState<ProgressApiRes | null>(null)
  const [newQuestionId, setNewQuestionId] = useState<string | null>(null)
  const params = useParams()
  const { showToast } = useToast()
  const {
    data: rfi,
    error,
    isLoading,
  } = useRfi({
    params: { rfiId: params.projectId || '' },
  })

  const lastQuestion = useMemo(() => rfi?.summaryQuestions[rfi?.summaryQuestions.length - 1], [rfi?.summaryQuestions])

  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: 2000,
  })

  const scrollToView = (direction: 'top' | 'bottom') => {
    const scrollImmediateTimeout = setTimeout(() => {
      if (direction === 'top') {
        refs.current?.summaryContainerRef?.scrollTo({ top: 0, behavior: 'smooth' })
      } else if (direction === 'bottom') {
        refs.current?.summaryContainerRef?.scrollTo({
          top: refs.current?.summaryContainerRef?.scrollHeight,
          behavior: 'smooth',
        })
      }
    }, 10)

    return () => {
      clearTimeout(scrollImmediateTimeout)
    }
  }

  const { mutateAsync: addSummaryQuestion } = useAddSummaryQuestion()

  const handleSubmitQuestion = async (questionText: string) => {
    const res = await addSummaryQuestion({ questionText: questionText, rfiId: rfi?.id })
    setTask(res.data)
    setNewQuestionId(res.data.resultObjectId)

    queryClient.setQueryData([ApiQueryKeys.RFI, { rfiId: rfi?.id }], (prev: any) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          summaryQuestions: [...prev.data.summaryQuestions, { questionText, proposedAnswer: null }],
        },
      }
    })
    scrollToView('bottom')
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    showToast({
      message: 'Copied to clipboard',
      type: 'success',
      duration: 4000,
    })
  }

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.SUMMARY_PAGE_VIEW,
    })
  }, [])

  useEffect(() => {
    if (taskStatus?.completed && task) {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI, { rfiId: rfi?.id }] })
      setTask(null)
    }
    if (taskStatus?.status?.includes('Error') || taskStatus.error) {
      showToast({
        message: 'An error occurred while retrieving the information. Try again or contact support.',
        type: 'error',
      })
      setTask(null)
      return
    }
  }, [rfi?.id, showToast, task, task?.id, taskStatus])

  // scroll to bottom when last question is answered
  useEffect(() => {
    if (lastQuestion?.id === newQuestionId && lastQuestion?.proposedAnswer) {
      scrollToView('bottom')
      setNewQuestionId(null)
    }
  }, [newQuestionId, lastQuestion?.id, lastQuestion?.proposedAnswer])

  return (
    <SummaryAndChat
      ref={refs}
      taskStatus={taskStatus}
      rfiAndSummary={{
        summary: rfi?.rfiSummary ?? '',
        summaryQuestions: rfi?.summaryQuestions ?? [],
        isLoadingRfi: isLoading,
        rfiError: error,
      }}
      scrollToView={scrollToView}
      isAnswerLoading={taskStatus?.completed === false}
      handleSubmitQuestion={handleSubmitQuestion}
      handleCopyToClipboard={copyToClipboard}
    />
  )
}
