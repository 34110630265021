import { AnalyticsActionType } from '@wppopen/core'
import { useOs } from '@wppopen/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useRfis } from 'api/queries/rfis/useRfis'
import { useFetchUserPermissions } from 'api/queries/users/useFetchUserPermissions'
import { navigation } from 'components/SidebarCmp'
import { agencyRoles } from 'constants/agencyRoles'

import EmptyState from './EmptyState'
import { ProjectDashboardView } from './ProjectDashboardView'
import { trackAnalytics, ANALYTICS_EVENTS } from '../../utils/analytics'

export interface SetApifilterProps {
  clientIds?: string[]
  marketIds?: string[]
  pitchTypeIds?: string[]
}
export default function HomePage() {
  const navigate = useNavigate()
  const {
    data: projects = [],
    error,
    isLoading,
    isFetching,
  } = useRfis({
    staleTime: 60 * 10 * 1000, // 10 minutes
  })
  const { osContext } = useOs()

  const { data: userPermissions } = useFetchUserPermissions({
    params: {
      account_ids: [osContext.tenant.azMeta.organizationsId],
    },
  })

  const allPermissions = userPermissions?.map(permission => permission.permissions) || []
  const hasPermission = allPermissions?.flat().includes(agencyRoles.RFI_AGENCY_MANAGE)

  const isEmpty = !isLoading && (error || (!error && !projects.length))

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.MY_PROJECTS_PAGE_VIEW,
    })
  }, [])

  if (isEmpty)
    return (
      <EmptyState
        agencyPermission={hasPermission}
        navigate={navigate}
        title="No Projects to Show"
        description="Create a new project to get started"
        action={{
          handleClick: () => navigate(navigation.newProject),
          cta: 'Add new',
        }}
      />
    )

  return (
    <ProjectDashboardView
      allProjectsData={projects}
      projects={projects}
      navigate={navigate}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      agencyPermission={hasPermission}
    />
  )
}
