import { WppButton, WppSpinner } from '@wppopen/components-library-react'

import { useDeleteUseCase } from 'api/mutations/use-cases/deleteUseCase'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { UseCase } from 'types/use-cases/useCase'

export const UseCaseDeleteModal = ({
  useCaseId,
  name,
  handleModalClose,
  handleDeleteSuccess,
}: {
  useCaseId: string
  name: string
  handleModalClose?: () => void
  handleDeleteSuccess?: () => void
}) => {
  const toast = useToast()
  const { mutateAsync: deleteUseCase, isPending } = useDeleteUseCase({
    onMutate: async currentUseCaseId => {
      const previousUseCases = queryClient.getQueryData<UseCase[]>([ApiQueryKeys.USE_CASES])
      queryClient.setQueryData(
        [ApiQueryKeys.USE_CASES],
        previousUseCases?.filter(item => item.id !== currentUseCaseId),
      )
      return { previousUseCases }
    },
    onSuccess: () => {
      handleDeleteSuccess?.()
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASES] })
      toast.showToast({
        message: 'Use Case deleted successfully',
        type: 'success',
      })
    },
  })
  const handleDeleteConfirm = async () => {
    try {
      deleteUseCase(useCaseId)
      // close modal
    } catch (e) {
      toast.showToast({
        message: 'Error deleting Use Case',
        type: 'error',
      })
    }
  }

  return (
    <>
      <h3 slot="header">Delete Use Case</h3>
      <p slot="body">
        This will permanently delete <strong>{name}</strong> from this tool, it will also remove it from the project.
        Are you sure you want to delete <strong>{name}</strong>?
      </p>

      <div slot="actions" className="flex flex-row justify-end gap-4">
        <WppButton variant="secondary" onClick={handleModalClose}>
          Cancel
        </WppButton>
        <WppButton variant="destructive" onClick={handleDeleteConfirm}>
          {isPending ? <WppSpinner size="s" color="var(--wpp-white-color)" /> : 'Delete'}
        </WppButton>
      </div>
    </>
  )
}
