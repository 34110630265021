import { MessageType } from 'components/chat/types'

import { SummaryMessage, SummaryQuestionsResponse } from '@/types/summaries/summary'

export const transformSummaryQuestions = (data: SummaryQuestionsResponse[]) =>
  data.reduce((acc, item) => {
    const question = {
      id: `${MessageType.QUESTION}-${item.id}`,
      content: item.questionText,
      type: MessageType.QUESTION,
      rfiId: item.rfiId,
    }
    const answer = {
      id: `${MessageType.ANSWER}-${item.id}`,
      content:
        item.proposedAnswer === null ? null : getAnswerOrErrorMsg(item.status as QuestionStatus, item.proposedAnswer),
      type: MessageType.ANSWER,
      rfiId: item.rfiId,
      status: item.status,
    }
    acc = [...acc, question, answer]
    return acc
  }, [] as SummaryMessage[])

const QuestionStatusMap = {
  0: 'NOT_ANSWERED',
  1: 'ANSWERED',
  2: 'TOKEN_LIMIT_REACHED',
  3: 'LLM_ERROR',
  4: 'OTHER_ERROR',
} as const

type QuestionStatus = keyof typeof QuestionStatusMap

const getAnswerOrErrorMsg = (status: QuestionStatus, answer: string) => {
  if (status === 1 && answer) {
    return answer
  }
  if (status === 0) {
    return 'It was not possible to find the information requested.'
  }
  return 'An error occurred while answering this question.'
}
