import { createUseQuery } from 'api/common/createUseQuery'
import { fetchClients } from 'api/fetchers/clients/fetchClients'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ClientType } from 'types/clients/client'

export const useClients = createUseQuery({
  queryKey: ApiQueryKeys.CLIENTS,
  fetcher: fetchClients,
  selector: res => res?.data ?? ([] as ClientType[]),
})
