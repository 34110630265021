import { createLazyQuery } from 'api/common/createUseQuery'
import { fetchFileUploadUrls } from 'api/fetchers/files/fetchFileUploadUrls'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

import { UploadUrl } from '@/types/files/uploadUrl'

export const useFileUploadUrls = createLazyQuery({
  queryKey: ApiQueryKeys.FILE_UPLOAD_URLS,
  fetcher: fetchFileUploadUrls,
  selector: res => res?.data ?? ([] as UploadUrl[]),
})
