import {
  WppIconTableSort,
  WppTypography,
  WppIconRemoveCircle,
  WppSelect,
  WppListItem,
  WppDivider,
  WppActionButton,
} from '@wppopen/components-library-react'
import { useMemo, useState } from 'react'

import { userRoles } from 'constants/userRoles'
import { CreatedOrUpdatedBy } from 'types/rfis/rfi'
import { sortAndSliceUsers } from 'utils/projectUtils'

import { LoadMore } from './load-more/LoadMore'
import { UserItem } from './user-item'
import { Role, IUser, IUserRole } from '../../types/users/userList'

interface IUsersList {
  users: IUser[]
  onRoleChange: ({ userId, role }: { userId: string; role: IUserRole }) => void
  onRemoveUser: (user: IUser) => void
  disabled: boolean
  userDetails: Omit<IUser, 'role'>
  creator?: CreatedOrUpdatedBy
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

type SortBy = 'Member' | 'Role'

export interface SortOption {
  sortBy: SortBy
  order: SortOrder
}

const DEFAULT_USERS_COUNT = 10
const LOAD_MORE_COUNT = 5

export function UsersList({ users, onRoleChange, onRemoveUser, disabled, userDetails, creator }: IUsersList) {
  const currentUser: IUser = {
    id: userDetails.id,
    firstname: userDetails.firstname,
    lastname: userDetails.lastname,
    avatarUrl: userDetails.avatarUrl ?? '',
    role: {
      id: '1',
      name: Role.Owner,
    },
    email: userDetails.email,
  }
  const Creator = creator ? users.find(mem => mem.email === creator.email) || currentUser : currentUser
  const [sortOption, setSortOption] = useState<SortOption>({
    sortBy: 'Member',
    order: SortOrder.ASC,
  })
  const [shownUsersCount, setShownUsersCount] = useState(DEFAULT_USERS_COUNT)

  const sortedUsers = useMemo(() => {
    return sortAndSliceUsers({ sortOption, users, usersCount: shownUsersCount })
  }, [sortOption, users, shownUsersCount])

  const onSortList = (sortBy: SortBy) => {
    setSortOption(prevState => ({
      sortBy,
      order: prevState.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
    }))
  }

  const onLoadMore = () => {
    const diff = users.length - shownUsersCount
    if (diff >= LOAD_MORE_COUNT) {
      setShownUsersCount(prevCount => prevCount + LOAD_MORE_COUNT)
      return
    }
    setShownUsersCount(prevCount => prevCount + diff)
  }

  const renderUserRow = ({ user, disableRoleChange = false }: { user: IUser; disableRoleChange?: boolean }) => {
    const isCurrentUserOwner = user.role.name === Role.Owner && user.email === currentUser.email
    return (
      <>
        <div className="flex my-4">
          <div className=" w-1/2">
            <UserItem
              avatarUrl={user?.avatarUrl || ''}
              firstName={user.firstname}
              lastName={user.lastname}
              email={user.email}
            />
          </div>
          <div className="flex gap-9 items-center w-1/2">
            <div className="w-[90%]">
              <WppSelect
                required
                disabled={disableRoleChange || disabled || undefined}
                placeholder="Select Role"
                value={(user.role?.name || user?.role) ?? Role.Contributor}
                onWppChange={e => {
                  onRoleChange({
                    userId: user.id,
                    role: userRoles.find(r => r.name === e.detail.value)!,
                  })
                }}
              >
                {user.email === Creator.email || isCurrentUserOwner ? (
                  <WppListItem key={user.role?.id} value={user.role?.name}>
                    <p slot="label">{user.role?.name}</p>
                  </WppListItem>
                ) : (
                  userRoles.map(role => (
                    <WppListItem key={role.name} value={role.name}>
                      <p slot="label">{role.name}</p>
                    </WppListItem>
                  ))
                )}
              </WppSelect>
            </div>
            {user.id !== Creator.id && !isCurrentUserOwner && (
              <WppActionButton onClick={() => onRemoveUser(user)} disabled={disabled}>
                <WppIconRemoveCircle />
              </WppActionButton>
            )}
          </div>
        </div>
        <WppDivider />
      </>
    )
  }

  return (
    <div className="p-4">
      <div className="flex w-full mb-4 max-h-[600px] overflow-y-auto">
        <div className="w-1/2">
          <div className="flex items-center gap-1 cursor-pointer" onClick={() => onSortList('Member')}>
            <WppTypography type="m-strong">Member</WppTypography>
            <WppIconTableSort />
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex items-center gap-1 cursor-pointer" onClick={() => onSortList('Role')}>
            <WppTypography type="m-strong">Role</WppTypography>
            <WppIconTableSort />
          </div>
        </div>
      </div>
      <WppDivider />

      {renderUserRow({ user: Creator, disableRoleChange: true })}
      {sortedUsers.length > 0 &&
        sortedUsers
          .filter(usr => usr?.email !== Creator?.email)
          .map(user => (
            <div key={user.id}>
              {renderUserRow({
                user,
                disableRoleChange: user.role.name === Role.Owner && user.email === currentUser.email,
              })}
            </div>
          ))}

      {users.length > shownUsersCount && (
        <div className="my-6 w-full">
          <LoadMore currentCount={shownUsersCount} total={users.length} onLoadMore={onLoadMore} />
        </div>
      )}
    </div>
  )
}
