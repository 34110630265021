import { WppAutocompleteCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppAutocomplete, WppListItem } from '@wppopen/components-library-react'
import { useState } from 'react'

import { useClients } from 'api/queries/clients/useClients'

export interface Props {
  disabled?: boolean
  onChange: (value: string) => void
  defaultClient?: { id: string; label: string } | null
}

export const ClientAutocomplete = ({ defaultClient = null, disabled = false, onChange }: Props) => {
  const { data: clientsData } = useClients()

  const items: { id: string; label: string }[] =
    defaultClient && clientsData?.find(client => client?.id === defaultClient?.id) === undefined
      ? [...clientsData.map(item => ({ id: item.id, label: item.name })), defaultClient]
      : clientsData
          .map(item => ({ id: item.id, label: item.name }))
          .sort((a, b) => (b.label.toLocaleLowerCase() < a.label.toLocaleLowerCase() ? 1 : -1))
  const [listValue, setListValue] = useState<{ id: string; label: string }[]>(defaultClient ? [defaultClient] : [])
  const [newClient, setNewClient] = useState('')
  const [suggestValue, setSuggestValue] = useState<{ id: string; label: string }[]>(items)
  const handleSearchChange = (e: WppAutocompleteCustomEvent<string>) => {
    const { detail } = e
    if (detail) {
      setNewClient(detail)
      setSuggestValue(items.filter(item => item.label.toLowerCase().includes(detail.toLowerCase())))
    }
  }
  return (
    <WppAutocomplete
      required
      className="w-full"
      showCreateNewElement
      displayBtnWhenListEmpty
      locales={{
        nothingFound: 'No clients found',
        more: 'More clients',
        selected: (count: number) => String(count),
        loading: 'Loading clients',
        createNewElement: `Add ${newClient}`,
      }}
      onWppCreateNewOption={e => {
        const newClient = e.detail
        onChange(newClient)
        setListValue([{ id: 'newClient', label: newClient }])
      }}
      disabled={disabled || undefined}
      aria-label="Client"
      title="Client"
      placeholder="Select or add new client"
      labelConfig={{
        text: 'Client',
      }}
      suggestions={items}
      suggestionsTitle="Clients"
      value={listValue}
      onWppSearchValueChange={handleSearchChange}
      onWppChange={e => {
        const selectedItem = e.detail.value[0]
        // const isNewClient = selectedItem === 'newClient'
        // find the item from the list of clients
        const listValue = items.find(item => selectedItem?.id === item.id || selectedItem === item?.id)
        // set the value to the list as an array
        setListValue(listValue ? [listValue] : e.detail.value)
        // pass the value to the callback function
        onChange(typeof listValue === 'string' ? listValue : listValue?.id || '')
      }}
    >
      {suggestValue?.map(item => (
        <WppListItem key={String(Math.random() * 100)} label={item.label} value={item.id || item.label}>
          <p slot="label">{item.label}</p>
        </WppListItem>
      ))}
    </WppAutocomplete>
  )
}
