// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YIQrG{height:42px;width:100%;padding:4px 4px 6px 4px;border-bottom-left-radius:var(--wpp-border-radius-m);border-bottom-right-radius:var(--wpp-border-radius-m)}.y4Yx3{color:var(--wpp-danger-color-500)}.IQD2M{color:var(--wpp-grey-color-700)}`, "",{"version":3,"sources":["webpack://./src/components/chat/input-actions/InputActions.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,UAAA,CACA,uBAAA,CACA,oDAAA,CACA,qDAAA,CAGF,OACE,iCAAA,CAGF,OACE,+BAAA","sourcesContent":[".inputActions {\n  height: 42px;\n  width: 100%;\n  padding: 4px 4px 6px 4px;\n  border-bottom-left-radius: var(--wpp-border-radius-m);\n  border-bottom-right-radius: var(--wpp-border-radius-m);\n}\n\n.questionCharacterLimitLabelError {\n  color: var(--wpp-danger-color-500);\n}\n\n.questionCharacterLimitLabel {\n  color: var(--wpp-grey-color-700);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputActions": `YIQrG`,
	"questionCharacterLimitLabelError": `y4Yx3`,
	"questionCharacterLimitLabel": `IQD2M`
};
export default ___CSS_LOADER_EXPORT___;
