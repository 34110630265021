import { rfiHelperApi } from 'api/index'

import { RfiQuestion } from '@/types/rfis/rfi'

export interface Props {
  question_id: string
}

export const fetchRfiQuestionId = ({ question_id }: Props) =>
  rfiHelperApi.get<RfiQuestion>(`/rfi_questions/${question_id}`)
