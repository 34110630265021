import { PitchType } from 'types/pitch-types/pitchType'
import { ProjectDetails } from 'types/rfis/rfi'
import { useGetMarketsByIds } from 'utils/projectUtils'

export const paginated = (page: number, currPage: number, pageSize: number, data: any[]) =>
  data.slice(page * pageSize, currPage * pageSize)

export const findMarketNameInMarkets = (markets: { market: string; id: string }[], marketIds: string[]) =>
  markets
    ?.filter(market => marketIds?.includes(market.id))
    ?.map(market => market.market)
    ?.join(', ') || 'names not found'

export const findClientsInClientsFromClientIds = (clients: { name: string; id: string }[], clientIds: string[]) =>
  clients
    ?.filter(client => clientIds?.includes(client.id))
    ?.map(client => client.name)
    ?.join(', ') || 'names not found'

export const findPitchesInPitchesFromPitchIds = (pitches: { id: string; name: string }[], pitchIds: string[]) =>
  pitches
    ?.filter(pitch => pitchIds?.includes(pitch.id))
    ?.map(pitch => pitch.name)
    ?.join(', ') || 'names not found'

export const findCreatedByNamesInCreatedBys = (
  AllcreatedBys: { name: string; email: string }[],
  createdBys: string[],
) =>
  AllcreatedBys?.filter(createdBy => createdBys?.includes(createdBy.email))
    ?.map(createdBy => createdBy.name)
    ?.join(', ') || 'names not found'

export const findLastUpdatedByNamesInupdatedBys = (
  allupdatedBys: { name: string; email: string }[],
  updatedBys: string[],
) =>
  allupdatedBys
    ?.filter(lastUpdatedBy => updatedBys?.includes(lastUpdatedBy.email))
    ?.map(lastUpdatedBy => lastUpdatedBy.name)
    ?.join(', ') || 'names not found'

/* clientNames */
export const useProjectFilterSelects = (allProjectsData: ProjectDetails[], allPitchTypes: PitchType[]) => {
  const marketIds = allProjectsData.map(project => project?.marketIds).flat()
  const marketOptions = useGetMarketsByIds(marketIds, true) || []
  //@ts-ignore
  const markets: { market: string; id: string }[] = Array.isArray(marketOptions)
    ? [...new Map(marketOptions.map(item => [item['market'], item])).values()].sort((a, b) =>
        //@ts-ignore
        a?.market.localeCompare(b?.market),
      )
    : []

  const clientNames = allProjectsData.map(project => ({ name: project.clientName, id: project.clientId }))
  const clientNameDeDupedById = [...new Map(clientNames.map(item => [item['id'], item])).values()].sort((a, b) =>
    a.name?.localeCompare(b.name),
  )

  /* PitchTypes */
  const pitchTypeIds = allProjectsData.map(project => project.pitchTypeIds).flat()
  const pitchIdsMapped = [
    ...new Set(
      allPitchTypes
        .filter(pitchType => pitchTypeIds.includes(pitchType.id))
        .map(pitchType => ({ id: pitchType.id, name: pitchType.typeDescription })),
    ),
  ]

  const createdBys = allProjectsData.map((project, index) => {
    const name = project.createdBy?.name || ''
    const email = project.createdBy?.email || ''
    return { name, email, id: index }
  })
  const createByDeDuped: { name: string; email: string }[] = [
    ...new Map(createdBys.map(item => [item['email'], item])).values(),
  ].sort((a, b) => a.name.localeCompare(b.name))
  const updatedBys = allProjectsData.map(project => project?.updatedBy).filter(item => item !== null)
  let lastUpdatedByDeDuped: { name: string; email: string }[] = []
  if (updatedBys.length > 0) {
    lastUpdatedByDeDuped = [...new Map(updatedBys.map(item => [item['email'], item])).values()].sort((a, b) =>
      a.name.localeCompare(b.name),
    )
  }

  const agencies = allProjectsData.map(project => project.agencies).flat()
  const agenciesDeduped: { name: string; id: string }[] = [
    ...new Map(agencies.map((item: { id: string; name: string }) => [item.id, item])).values(),
  ].sort((a, b) => a.name.localeCompare(b.name))

  return {
    clients: clientNameDeDupedById,
    pitches: pitchIdsMapped,
    createdBys: createByDeDuped,
    updatedBys: lastUpdatedByDeDuped,
    agencies: agenciesDeduped,
    markets,
  }
}
