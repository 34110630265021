import { WppButton } from '@wppopen/components-library-react'

import { useDeleteRfi } from 'api/mutations/rfis/useDeleteRfi'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { ProjectDetails } from 'types/rfis/rfi'

export const ProjectDeleteModal = ({
  projectId,
  name,
  handleModalClose,
}: {
  projectId: string
  name: string
  handleModalClose: () => void
}) => {
  const toast = useToast()
  const { mutateAsync: deleteProject } = useDeleteRfi({
    onMutate: async projectId => {
      // 1. Get the current list of projects from the cache
      const previousProjects = queryClient.getQueryData<ProjectDetails[]>([ApiQueryKeys.RFIS])
      // 2. Optimistically remove the project from the cache
      queryClient.setQueryData(
        [ApiQueryKeys.RFIS],
        previousProjects?.filter(project => project.id !== projectId),
      )
      // 3. Return a context object for potential rollback
      return { previousProjects }
    },
    onSuccess: () => {
      // Invalidate the 'projects' query to refetch the updated data
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
      toast.showToast({ message: 'Project deleted successfully', type: 'success' })
    },
  })

  const handleDeleteConfirm = () => {
    try {
      deleteProject(projectId)
      // close modal
      handleModalClose()
    } catch (e) {
      toast.showToast({ message: 'Error deleting project', type: 'error' })
    }
  }

  return (
    <>
      <h3 slot="header">Delete Project</h3>
      <p slot="body">
        This will permanently delete <strong>{name}</strong> project for all projects. Are you sure you want to deleted
        this project?
      </p>

      <div slot="actions" className="flex flex-row justify-end gap-4">
        <WppButton variant="secondary" onClick={handleModalClose}>
          Cancel
        </WppButton>
        <WppButton variant="destructive" onClick={handleDeleteConfirm}>
          Delete
        </WppButton>
      </div>
    </>
  )
}
