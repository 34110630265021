import { createUseQuery } from 'api/common/createUseQuery'
import { fetchUseCase } from 'api/fetchers/use-cases/fetchUseCase'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { UseCase } from 'types/use-cases/useCase'

export const useUseCase = createUseQuery({
  queryKey: ApiQueryKeys.USE_CASE,
  fetcher: fetchUseCase,
  selector: res => res?.data ?? ({} as UseCase),
})
