import { rfiHelperApi } from 'api'
import { UploadUrl } from 'types/files/uploadUrl'

export interface Props {
  file_names: string[]
  file_type: 'usecase' | 'rfi' | 'agency_file'
}

export const fetchFileUploadUrls = ({ file_names, file_type }: Props) => {
  const params = new URLSearchParams()
  file_names.forEach(el => {
    params.append('file_names', el)
  })

  params.append('file_type', file_type)

  return rfiHelperApi.get<UploadUrl[]>(`/gcp/generate_upload_urls?${params.toString()}`)
}
