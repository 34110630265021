import { InputChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppIconArrow,
  WppIconSearch,
  WppInput,
  WppSkeleton,
  WppSpinner,
  WppTypography,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useRfi } from 'api/queries/rfis/useRfi'
import { useUseCases } from 'api/queries/use-cases/useUseCases'
import DownloadZip from 'components/DownloadZip'
import EmptyState from 'components/EmptyState'
import { ProjectDetails } from 'types/rfis/rfi'
import { UseCase } from 'types/use-cases/useCase'
import { useGetPitchTypesByIds } from 'utils/projectUtils'

import FileCmp from './FileCmp'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

function RelevantNotesPage({ rfi }: { rfi: ProjectDetails }) {
  const [selectedFile, setSelectedFile] = useState<UseCase | null>(null)
  const agencyIds = rfi.agencies.map(agency => agency.id)
  const { data: useCases, error, isLoading } = useUseCases({ params: { agencyIds, pitchTypeIds: rfi.pitchTypeIds } })

  const rfiPitchTypes = useGetPitchTypesByIds(rfi.pitchTypeIds)

  const [search, setSearch] = useState('')

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.RELEVANT_CASES_PAGE_VIEW,
    })
  }, [])

  const handleSearchChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    setSearch(event.detail.value ?? '')
  }

  const filteredUseCases = useMemo(() => {
    const sortedCasesWithMatchingPitchType = useCases
      .filter(useCase => useCase.pitchTypes.some(pitchType => rfiPitchTypes?.includes(pitchType)))
      .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLocaleLowerCase()))
    const rest = useCases.filter(useCase => useCase.pitchTypes.every(pitchType => !rfiPitchTypes?.includes(pitchType)))

    return [...sortedCasesWithMatchingPitchType, ...rest].filter(
      useCase =>
        useCase.title.toLowerCase().includes(search.toLowerCase()) ||
        useCase.markets.join(', ').toLowerCase().includes(search.toLowerCase()) ||
        useCase.client.toLowerCase().includes(search.toLowerCase()),
    )
  }, [search, useCases, rfiPitchTypes])

  const renderView = () => {
    if (isLoading) {
      return (
        <div className="flex gap-3 flex-wrap">
          <WppSkeleton width="30%" height={255} />
          <WppSkeleton width="30%" height={255} />
          <WppSkeleton width="30%" height={255} />
        </div>
      )
    }
    if (!isLoading && (error || (!error && !useCases.length))) {
      return (
        <div className="flex flex-row items-center justify-center">
          <EmptyState title="Nothing to Show" description="There are no items to be shown here" />
        </div>
      )
    }
    return (
      <>
        <div className="flex flex-row items-center flex-wrap justify-start gap-4">
          {filteredUseCases.length > 0 &&
            filteredUseCases?.map(useCase => (
              <FileCmp key={useCase.id} useCase={useCase} onClick={useCase => setSelectedFile(useCase)} />
            ))}
        </div>

        {useCases.length > 0 && filteredUseCases.length === 0 && (
          <EmptyState title="No Results" description="Try searching by different keywords or set different filters" />
        )}
      </>
    )
  }

  if (selectedFile) {
    return (
      <div>
        <div className="flex flex-row items-center gap-4">
          <WppActionButton onClick={() => setSelectedFile(null)} variant="secondary">
            <WppIconArrow direction="left" slot="icon-start" />
          </WppActionButton>
          <WppTypography type="xl-heading">Relevant Cases and Pitches / {selectedFile.title}</WppTypography>
        </div>
        <div className="bg-[#F8F9FB] rounded-lg p-4 mt-4">
          <WppTypography color="#121619" type="s-strong">
            {selectedFile.title}
          </WppTypography>
          <WppTypography className="mt-3" color="#4D5358" type="s-body">
            <WppTypography className="max-w-full whitespace-pre-wrap break-words" type="s-body">
              <span dangerouslySetInnerHTML={{ __html: selectedFile.summary.replace(/\n/g, '<br />') }} />
            </WppTypography>
          </WppTypography>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-center justify-between">
        <WppTypography type="xl-heading">Relevant Cases and Pitches</WppTypography>
        <DownloadZip
          disabled={useCases.every(u => !u.fileUrl)}
          urls={useCases.filter(u => u.fileUrl).map(u => u.fileUrl)}
        />
      </div>

      <div className="flex flex-col gap-2">
        <WppTypography className="text-grey" type="s-light">
          Check out proposed use cases and pitches that can help you.
        </WppTypography>
        <WppInput
          name="use-case-search"
          className="w-[300px]"
          placeholder="Search"
          value={search}
          disabled={isLoading || !useCases.length}
          onWppChange={handleSearchChange}
        >
          <WppIconSearch slot="icon-start" aria-label="Search icon" />
        </WppInput>
      </div>

      {renderView()}
    </div>
  )
}

export default function RelevantNotesPageWrapper() {
  const params = useParams()
  const {
    data: rfi,
    isLoading: isLoadingRfi,
    error,
  } = useRfi({
    params: { rfiId: params.projectId || '' },
  })
  const { isLoading: isLoadingPitchTypes } = usePitchTypes()

  if (isLoadingRfi || isLoadingPitchTypes) {
    return (
      <div className="flex items-center justify-center">
        <WppSpinner size="m" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex flex-row items-center justify-center">
        <EmptyState title="Nothing to Show" description="There are no items to be shown here" />
      </div>
    )
  }

  return <RelevantNotesPage rfi={rfi} />
}
