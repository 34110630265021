import { PaginationChangeEventDetail, SelectChangeEventDetail } from '@wppopen/components-library'
import {
  WppListItem,
  WppModal,
  WppPagination,
  WppSelect,
  WppSpinner,
  WppTypography,
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useState, useRef, useCallback, useMemo, useEffect } from 'react'

import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useRfis } from 'api/queries/rfis/useRfis'
import { ProjectsFilterGroup } from 'components/ProjectsFilterGroup'
import { sortType } from 'components/ProjectsFilterGroup/types'
import { ProjectDetails } from 'types/rfis/rfi'

import { ProjectDeleteModal } from './ProjectDeleteModal'
import ProjectPreviewCard from './ProjectPreviewCard'
import { ActiveFilters, IProjectDashboardView } from './types'
import { useProjectSortFilters } from './useProjectSortFilters'
import { paginated, useProjectFilterSelects } from './utils'

export enum ControlValues {
  ALL = 'All',
  MY_PROJECTS = 'My projects',
  SHARED_WITH_ME = 'Shared with me',
}

export const ProjectDashboardView = ({
  navigate,
  allProjectsData,
  isLoading,
  isFetching,
  error,
  agencyPermission,
}: IProjectDashboardView) => {
  const initPageSize = 9
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({})

  const [appliedApiFilters, setAppliedFilters] = useState({
    /* like this: */
    // params: {
    //   query: {},
    // },
  })
  const {
    data: filteredProjects = [],
    // error: errorFiltering,
    isLoading: isLoadingFiltering,
    isFetching: isFetchingFiltering,
  } = useRfis({ ...appliedApiFilters, refetchOnWindowFocus: false })
  // const [displayData, setDisplayData] = useState<ProjectDetails[]>(filteredProjects || allProjectsData)
  const user = useOs()
  const { email } = user?.osContext?.userDetails

  const [isDefaultState, setIsDefaultState] = useState<boolean>(true)

  const [paginationPageSize, setPaginationPageSize] = useState<number>(initPageSize)
  const [currPage, setCurrPage] = useState<number>(1)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<{ id: string; name: string }>({ id: '', name: '' })
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [clientNamesSelectValue, setClientNamesSelectValue] = useState<string[]>([])
  const [agencieSelectValues, setAgencieSelectValues] = useState<string[]>([])
  const [pitchTypeSelectValues, setPitchTypeSelectValues] = useState<string[]>([])
  const [marketsSelectValues, setMarketsSelectValues] = useState<string[]>([])
  const [createBySelectValues, setCreateBySelectValues] = useState<string[]>([])
  const [updatedByselectValues, setupdatedByselectValues] = useState<string[]>([])

  const [controlValue, setControlValue] = useState<ControlValues>(ControlValues.ALL)
  const [controlValueApplied, setControlValueApplied] = useState<ControlValues>(ControlValues.ALL)
  const filterGroupContainerRef = useRef<HTMLDivElement>(null)
  const [{ sortAsc, activeSortType }, handleSortFilters] = useProjectSortFilters('dateFromTo')
  const [sortedData, setSortedData] = useState<ProjectDetails[]>([])

  const { data: allPitchTypes } = usePitchTypes({
    refetchOnWindowFocus: false,
  })
  let { clients, markets, pitches = [], createdBys, agencies } = useProjectFilterSelects(allProjectsData, allPitchTypes)
  const isFiltering = isLoadingFiltering || isFetchingFiltering

  const handleResetFilters = () => {
    // setActiveFilters({})
    // setAppliedFilters({})
    setControlValue(ControlValues.ALL)
    handleCancelFilters(true)
    setTimeout(() => {
      setIsDefaultState(true)
      handleSortDisplayData(activeSortType)
    }, 10)
  }

  const handleApplyFilters = (activeFilters = {}) => {
    if (marketsSelectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        marketIds: marketsSelectValues.join(','),
      }
    }
    if (clientNamesSelectValue.length > 0) {
      activeFilters = {
        ...activeFilters,
        clientIds: clientNamesSelectValue.join(','),
      }
    }
    if (pitchTypeSelectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        pitchTypeIds: pitchTypeSelectValues.join(','),
      }
    }
    if (createBySelectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        createdBys: createBySelectValues.join(','),
      }
    }
    if (updatedByselectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        updatedBys: updatedByselectValues.join(','),
      }
    }
    applyAllFilters(activeFilters)
    setControlValueApplied(controlValue)
  }

  const applyAllFilters = (activeFilters: ActiveFilters) => {
    if (Object.keys(activeFilters).length > 0) {
      const params = {
        query: {
          ...activeFilters,
        },
      }
      setAppliedFilters({ params })
    } else {
      setAppliedFilters({})
    }
    setActiveFilters(activeFilters)
  }

  const handleCancelFilters = (reset = false) => {
    if (reset) {
      setMarketsSelectValues([])
      setClientNamesSelectValue([])
      setPitchTypeSelectValues([])
      setCreateBySelectValues([])
      setupdatedByselectValues([])
    } else {
      setControlValue(controlValueApplied)
      setMarketsSelectValues(activeFilters?.marketIds?.split(',') || [])
      setClientNamesSelectValue(activeFilters?.clientIds?.split(',') || [])
      setPitchTypeSelectValues(activeFilters?.pitchTypeIds?.split(',') || [])
      setCreateBySelectValues(activeFilters?.createdBys?.split(',') || [])
      setupdatedByselectValues(activeFilters?.updatedBys?.split(',') || [])
    }
  }

  /* Search */
  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const handlePaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail
    if (paginationPageSize !== itemsPerPage) {
      setPaginationPageSize(itemsPerPage)
    }
    if (currPage !== page) {
      setCurrPage(page)
    }
  }

  const fetchingAll = isLoading || isFetching || isFiltering

  const handleAgencySelectChange = useCallback((e: CustomEvent<SelectChangeEventDetail>) => {
    setAgencieSelectValues(e.detail.value)
  }, [])

  const displayData = useMemo(() => {
    if (!isLoading && !isFetching) {
      let newDisplayedData = filteredProjects

      // Apply control filter
      if (controlValueApplied === ControlValues.MY_PROJECTS && email) {
        newDisplayedData = newDisplayedData.filter(project => project.createdBy.email === email)
      }
      if (controlValueApplied === ControlValues.SHARED_WITH_ME) {
        newDisplayedData = newDisplayedData.filter(
          project =>
            project.createdBy.email !== email &&
            project.projectMembers.some(member => member?.memberDetail.email === email),
        )
      }

      // Apply agency filter
      if (agencieSelectValues.length > 0) {
        newDisplayedData = newDisplayedData.filter(project =>
          project.agencies.some(agency => agencieSelectValues.includes(agency.id)),
        )
      }

      // Apply search
      if (searchTerm.length > 2) {
        const searchResults = newDisplayedData
          .filter(project => project?.projectName.toLowerCase().includes(searchTerm.toLowerCase()))
          .filter(project => {
            return (
              agencieSelectValues.length < 1 || project.agencies.some(agency => agencieSelectValues.includes(agency.id))
            )
          })

        setCurrPage(1)
        newDisplayedData = searchResults
      }
      setSortedData(newDisplayedData)
      return newDisplayedData
    } else {
      setSortedData([])
      return []
    }
  }, [isLoading, isFetching, filteredProjects, controlValueApplied, email, agencieSelectValues, searchTerm])

  /* Pagination */
  const isPaginated = useMemo(
    () => displayData.length > initPageSize && !isFiltering && !isLoading && !isFetching,
    [displayData.length, isFetching, isFiltering, isLoading],
  )
  let perPageOptions = [initPageSize]
  displayData.length > 9 && perPageOptions.push(12)
  displayData.length > 12 && perPageOptions.push(15)

  useEffect(() => {
    if (
      marketsSelectValues.length > 0 ||
      clientNamesSelectValue.length > 0 ||
      pitchTypeSelectValues.length > 0 ||
      createBySelectValues.length > 0 ||
      updatedByselectValues.length > 0 ||
      controlValue !== ControlValues.ALL
    ) {
      setIsDefaultState(false)
    }

    if (
      marketsSelectValues.length === 0 &&
      clientNamesSelectValue.length === 0 &&
      pitchTypeSelectValues.length === 0 &&
      createBySelectValues.length === 0 &&
      updatedByselectValues.length === 0 &&
      controlValue === ControlValues.ALL
    ) {
      setIsDefaultState(true)
    }
  }, [
    clientNamesSelectValue.length,
    controlValue,
    createBySelectValues.length,
    displayData,
    marketsSelectValues.length,
    pitchTypeSelectValues.length,
    updatedByselectValues.length,
  ])

  const handleSortDisplayData = useCallback(
    (type: sortType, preserveDirection = true) => {
      const sortedDisplayData = handleSortFilters(displayData, type, preserveDirection)
      setSortedData(sortedDisplayData)
    },
    [handleSortFilters, displayData],
  )

  useEffect(() => {
    handleSortDisplayData(activeSortType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData])

  return (
    <div className="px-4">
      <div className="flex justify-start mb-4">
        <WppTypography type="2xl-heading">My Projects</WppTypography>
      </div>
      <div className="flex justify-center mb-4" ref={filterGroupContainerRef}>
        <ProjectsFilterGroup
          navigate={navigate}
          agencyPermission={agencyPermission}
          disabled={isLoading || isFetching || isFiltering}
          handleApplyFilters={handleApplyFilters}
          handleResetFilters={handleResetFilters}
          handleCancelFilters={handleCancelFilters}
          handleSearchChange={handleSearchChange}
          controlValue={controlValue}
          setFilterControlValue={setControlValue}
          numOfSelectedFilters={Object.keys(activeFilters).length}
          resetFiltersVisible={!isDefaultState}
          handleSortFilter={handleSortDisplayData}
          activeSortType={activeSortType}
          isAsc={sortAsc}
          agencyDropdown={
            <WppSelect
              aria-label="Agencies"
              className="w-60"
              title="Agencies"
              placeholder="Agency"
              size="s"
              type="multiple"
              disabled={isFiltering || isLoading || isFetching}
              withSearch
              withFolder
              value={agencieSelectValues}
              onWppChange={handleAgencySelectChange}
            >
              {(agencies as { name: string; id: string }[])?.map(item => (
                <WppListItem key={item.id} value={item.id}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          }
          filters={
            <>
              <WppSelect
                aria-label="Markets"
                title="Markets"
                placeholder="Select markets"
                type="multiple"
                labelConfig={{
                  text: 'Markets',
                }}
                withSearch
                withFolder
                value={[...marketsSelectValues]}
                onWppChange={e => {
                  setMarketsSelectValues(e.detail.value)
                }}
              >
                {(markets as { market: string; id: string }[])?.map(item => (
                  <WppListItem key={item.id} value={item.id}>
                    <p slot="label">{item.market}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Clients"
                title="Clients"
                placeholder="Select clients"
                type="multiple"
                labelConfig={{
                  text: 'Clients',
                }}
                withFolder
                withSearch
                value={clientNamesSelectValue}
                onWppChange={e => {
                  const clientValues = e.detail.value.reduce(
                    (accu: string[], item: { name: string; id: string } | string) => {
                      if (typeof item === 'string') {
                        accu.push(item)
                      }
                      return accu
                    },
                    [],
                  )

                  setClientNamesSelectValue(clientValues)
                }}
              >
                {clients?.map(item => (
                  <WppListItem key={item.id} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Pitch type"
                title="Pitch type"
                placeholder="Select pitch type"
                type="multiple"
                labelConfig={{
                  text: 'Pitch type',
                }}
                withSearch
                value={pitchTypeSelectValues}
                withFolder
                onWppChange={e => {
                  setPitchTypeSelectValues(e.detail.value)
                }}
              >
                {pitches?.map(item => (
                  <WppListItem key={item.id} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Created by"
                title="Created by"
                placeholder="Select created by"
                type="multiple"
                labelConfig={{
                  text: 'Created by',
                }}
                withSearch
                withFolder
                value={createBySelectValues}
                onWppChange={e => {
                  setCreateBySelectValues(e.detail.value)
                }}
                dropdownConfig={{ placement: 'top' }}
              >
                {createdBys?.map(item => (
                  <WppListItem key={item.email} value={item.email}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </>
          }
        />
      </div>
      {!error && fetchingAll && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {!error && sortedData && !fetchingAll && (
        <div className={`${isPaginated ? '' : 'pb-16'} grid grid-cols-3 gap-6 relative`}>
          {paginated(currPage - 1, currPage, paginationPageSize, sortedData).map((project: any) => (
            <ProjectPreviewCard
              key={project.id}
              {...project}
              handleSelectedProject={project => {
                setIsEditModalOpen(true)
                setSelectedProject(project)
              }}
              closeDeleteModal={() => setIsEditModalOpen(false)}
            />
          ))}
          {sortedData.length === 0 && !error && !isFetching && !isLoading && !isFiltering && 'No results found'}
        </div>
      )}
      <WppModal open={isEditModalOpen} onWppModalClose={() => setIsEditModalOpen(false)} size="s" disableOutsideClick>
        <ProjectDeleteModal
          projectId={selectedProject?.id || ''}
          name={selectedProject?.name || ''}
          handleModalClose={() => {
            setIsEditModalOpen(false)
          }}
        />
      </WppModal>
      {isPaginated && (
        <WppPagination
          count={displayData.length}
          itemsPerPage={perPageOptions}
          activePageNumber={currPage}
          pageSelectThreshold={3}
          data-testid="pagination-list"
          selectedItemPerPage={paginationPageSize}
          onWppChange={handlePaginationChange}
          className="pb-16 mt-4 mb-4"
        />
      )}
    </div>
  )
}
