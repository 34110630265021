import { createUseQuery } from 'api/common/createUseQuery'
import { fetchUserPermissions } from 'api/fetchers/users/fetchUserPermissions'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

import { UserPermissionsResponse } from '@/types/users/userList'

export const useFetchUserPermissions = createUseQuery({
  fetcher: fetchUserPermissions,
  selector: res => res?.data ?? ([] as UserPermissionsResponse),
  queryKey: ApiQueryKeys.USER_ADMIN,
})
