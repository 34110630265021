import { createUseQuery } from 'api/common/createUseQuery'
import { fetchAgency } from 'api/fetchers/agencies/fetchAgency'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Agency } from 'types/agencies/agencies'

export const useGetAgency = createUseQuery({
  queryKey: ApiQueryKeys.AGENCIES,
  fetcher: fetchAgency,
  selector: res => res?.data ?? ({} as Agency),
})
