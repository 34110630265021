import { TextareaInputChangeEventDetail } from '@wppopen/components-library'
import { WppTextareaInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppTextareaInput } from '@wppopen/components-library-react'

import styles from './QuestionInput.module.scss'
import InputActions from '../input-actions/InputActions'

interface QuestionInterfaceProps {
  onInput: (value: string) => void
  question: string
  answerIsLoading: boolean
  handleSubmit: () => void
  disabled?: boolean
  placeholder?: string
}

export const QuestionInputNew = ({
  onInput,
  question,
  answerIsLoading,
  handleSubmit,
  disabled,
  placeholder = 'Ask a question to explore more options...',
}: QuestionInterfaceProps) => {
  const handleChange = (event: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => {
    onInput(event.target.value)
  }
  const handleEnter = (event: React.KeyboardEvent<HTMLWppTextareaInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className={styles.inputContainer}>
      <WppTextareaInput
        name="textAreaInput"
        disabled={disabled}
        placeholder={placeholder}
        value={question}
        className={styles.input}
        onWppChange={handleChange}
        onKeyDown={handleEnter}
      />

      <InputActions disabled={disabled} onSubmit={handleSubmit} question={question} answerIsLoading={answerIsLoading} />
    </div>
  )
}
